import 'swiper/css/pagination';

import cn from 'classnames';
import React, { FC, useCallback, useRef, useState } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { Image, Video } from '@commerce/types/common';
import { Renderer } from '@components/screen/factory';
import { ImageOrVideo, Text } from '@components/ui';
import { FontSizes, FontTypes } from '@components/ui/types/ContentfulTextOption';
import EffectMaterial from '@lib/swiper/effects/effect-material.esm';
import { isVideo } from '@lib/video';

import Button from '../Button/Button';
import SwiperPagination from './SwiperPagination';

import style from './HeroCarousel.module.scss';

interface SlideContent {
  id: string;
  content: {
    internalName: string;
    header?: string;
    subheader?: string;
    primaryCta?: string;
    primaryUrl?: string;
    secondaryCta?: string;
    secondaryUrl?: string;
    alignment?: 'Left' | 'Center' | 'Right';
    media: Image | Video;
    mobileMedia: Image | Video;
    videoThumbnail: Image;
    mobileVideoThumbnail: Image;
    isBlackText?: boolean;
    headerFontSize?: FontSizes;
    headerFontType?: FontTypes;
    primaryCtaColor: 'Purple' | 'Black' | 'White';
  };
}
interface ModuleOverview {
  interval: number;
  slides: SlideContent[];
}

const HeroCarousel: FC<ModuleOverview> = ({ slides, interval }) => {
  const sliderRef = useRef<SwiperRef | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const onAutoplayTimeLeft = useCallback(
    (s: any, time: any, progress: number) => {
      paginationRef.current?.style.setProperty('--progress', `${100 * (1 - progress)}%`);
    },
    [paginationRef]
  );

  const handleSlideChange = () => {
    setActiveSlide(sliderRef.current?.swiper.realIndex as number);
  };

  // this is to run animations loop properly if less than 5 slides are set
  if (slides.length < 5) {
    // eslint-disable-next-line no-param-reassign
    slides = slides.concat(slides);
  }

  return (
    <div className={style.carouselSection}>
      <Swiper
        ref={sliderRef}
        className={style.swiperMaterial}
        modules={[Autoplay, Pagination, EffectMaterial]}
        effect="material"
        spaceBetween={10}
        slidesPerView={1.15}
        speed={700}
        centeredSlides
        loop
        grabCursor
        slideToClickedSlide
        pagination={{
          el: `.${style.materialPagination}`,
          clickable: true,
          bulletActiveClass: style.materialPaginationBulletActive,
          renderBullet(index, className) {
            return `<span class="${cn(style.materialPaginationBullet, className)}"><div class="${
              style.wormLoader
            }"></div></span>`;
          },
        }}
        autoplay={{
          disableOnInteraction: false,
          delay: interval || 4000,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          800: {
            slidesPerView: 2,
          },
        }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSlideChange={handleSlideChange}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={`slide-${slide.id + index}`}>
            <div className={cn(style.swiperMaterialWrapper, 'swiper-material-wrapper')}>
              <div className={style.swiperMaterialContent}>
                {slide.content.media && (
                  <ImageOrVideo
                    {...slide.content.media}
                    className={cn(style.materialCarouselMedia, 'hidden sm:block')}
                    data-swiper-material-scale={1.25}
                    type={isVideo(slide.content.media.type) ? slide.content.media.type! : 'image'}
                    thumbnail={slide.content.videoThumbnail?.url || undefined}
                    renderAsThumbnail={!!isVideo(slide.content.media.type)}
                    playOnActive={isVideo(slide.content.media.type) && activeSlide === index}
                  />
                )}
                {slide.content.mobileMedia && (
                  <ImageOrVideo
                    {...slide.content.mobileMedia}
                    className={cn(style.materialCarouselMedia, 'visible sm:invisible')}
                    type={isVideo(slide.content.media.type) ? slide.content.media.type! : 'image'}
                    thumbnail={slide.content.mobileVideoThumbnail?.url || undefined}
                    renderAsThumbnail={!!isVideo(slide.content.media.type)}
                    playOnActive={isVideo(slide.content.media.type) && activeSlide === index}
                  />
                )}
                <div
                  className={cn(
                    style.materialCarouselCopy,
                    slide.content.alignment === 'Center' ? 'items-center text-center' : 'items-start',
                    'swiper-material-animate-opacity'
                  )}
                  style={{ color: slide.content.isBlackText ? 'var(--black)' : 'var(--white)' }}
                >
                  {slide.content.header && (
                    <Text
                      variant={index === 0 ? 'heading-1' : 'heading-2'} // first slide header H1, the rest H2
                      className={cn(
                        style.slideHeader,
                        style[slide.content.headerFontSize || FontSizes.L],
                        style[slide.content.headerFontType || FontTypes.Normal]
                      )}
                    >
                      {slide.content.header}
                    </Text>
                  )}
                  {slide.content.subheader && <Text>{slide.content.subheader}</Text>}
                  <div className={style.materialCarouselCtas}>
                    {slide.content.primaryCta && (
                      <Button
                        className={cn(
                          style.primaryCta,
                          style[`primaryCta${slide.content.primaryCtaColor || 'Purple'}`]
                        )}
                        variant="cta"
                        href={slide.content.primaryUrl}
                      >
                        {slide.content.primaryCta}
                      </Button>
                    )}
                    {slide.content.secondaryCta && (
                      <Button className={style.secondaryCta} variant="cta" href={slide.content.secondaryUrl}>
                        {slide.content.secondaryCta}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <SwiperPagination ref={paginationRef} />
    </div>
  );
};

export default Renderer({ name: 'moduleHeroCarousel' })(HeroCarousel);
