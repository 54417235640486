import { FC } from 'react';

import { Renderer } from '@components/screen/factory';
import { Button, Text } from '@components/ui';

import NextStepsTimeline from '../NextStepsTimeline/NextStepsTimeline';
import { NextStepsProps } from '../types/NextSteps';

const NextSteps: FC<NextStepsProps> = ({
  header,
  subHeader,
  timeline,
  footerNote,
  letsGoCta: letsGoCtaText,
  letsGoCtaLink,
  backToShippingCta: backToShippingCtaText,
  backToShippingCtaLink,
}) => {
  return (
    <div className="bg-[#8652FF] flex flex-col justify-between ">
      <div>
        <div className="flex justify-center flex-col items-center pt-8">
          <Text className="text-white uppercase pb-1">{header}</Text>
          <Text variant="heading-2" color="#fff" className="max-w-[300px] text-center">
            {subHeader}
          </Text>
        </div>
        <div className=" flex justify-center py-10">
          <NextStepsTimeline timeline={timeline} />
        </div>
      </div>
      <div className="flex flex-col p-7 bg-white rounded-t-2xl">
        <Text color="#8652FF" variant="xsmall" className="text-center">
          {footerNote}
        </Text>
        <div className="flex flex-col justify-center items-center pt-4 gap-4 ">
          <Button variant="cta" className="w-[320px]" href={letsGoCtaLink}>
            {letsGoCtaText}
          </Button>
          <Button variant="link" className="text-[#8652FF] font-bold" href={backToShippingCtaLink}>
            {backToShippingCtaText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Renderer({ name: 'screenNextSteps' })(NextSteps);
