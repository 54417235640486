import { Entry } from '@services/contentful/types';
import cn from 'classnames';
import { FC } from 'react';

import { Image } from '@commerce/types/common';
import Pricing from '@components/product/Pricing/Pricing';
import { Renderer } from '@components/screen/factory';
import { Button, Container, ImageOrVideo, Link, Text } from '@components/ui';

import { ModuleProduct } from '../types/ModuleProduct';

import s from './PromoBumper.module.scss';

interface Props {
  currencyCode?: string;
  headline: string;
  eyebrow?: string;
  body: string;
  product?: Entry<ModuleProduct>;
  ctaTitle: string;
  ctaUrl: string;
  theme: string;
  images?: Image[];
  mobileImages?: Image[];
  lazyLoadImage?: boolean;
}

const getTheme = (rawTheme: string): string => rawTheme.split('(')[0]?.trim() || '';

const getClassName = (isMobile = false, doubleImage = false): string =>
  cn({
    [s.imageMobile]: isMobile,
    [s.image]: !isMobile,
    [s.doubleImage]: doubleImage,
  });

const PromoBumper: FC<Props> = ({
  currencyCode,
  headline,
  eyebrow,
  body,
  product,
  ctaTitle,
  ctaUrl,
  theme: rawTheme,
  images,
  mobileImages,
  lazyLoadImage = false,
}) => {
  const theme = getTheme(rawTheme);
  const { price, salePrice } = product?.content || {};
  const showPricing = typeof price !== 'undefined' && typeof salePrice !== 'undefined';
  const singleImage = images?.length === 1;

  return (
    <Container mobilePadding>
      <Link className={cn(s.wrapper, s[theme])} href={ctaUrl}>
        {images?.[0] && (
          <ImageOrVideo
            type="image"
            {...images[0]}
            className={getClassName(undefined, !singleImage)}
            containerClassName={s.imageContainer}
            loading={lazyLoadImage ? 'lazy' : 'eager'}
          />
        )}
        {mobileImages?.[0] && (
          <ImageOrVideo type="image" {...mobileImages[0]} className={getClassName(true, !singleImage)} loading="lazy" />
        )}
        <div className={cn(s.contentWrapper, { [s.single]: singleImage })}>
          <div className={s.textContent}>
            {eyebrow && (
              <Text variant="eyebrow" className={cn(s.eyebrow, 'mb-1 text-center')} asElement="span">
                {eyebrow}
              </Text>
            )}
            <Text variant="header-4" className={cn('mb-4 text-center', s.headline)} asElement="h2">
              {headline}
            </Text>
            <Text variant="base" className={cn('text-center mb-4', s.body)}>
              {body}
            </Text>
            {showPricing && (
              <Pricing originalPrice={price} price={salePrice} currencyCode={currencyCode} className={s.pricing} />
            )}
          </div>
          <Button variant="cta" className={s.cta}>
            {ctaTitle}
          </Button>
        </div>
        {images?.[1] && (
          <ImageOrVideo
            type="image"
            {...images[1]}
            className={getClassName(undefined, !singleImage)}
            containerClassName={s.imageContainer}
            loading={lazyLoadImage ? 'lazy' : 'eager'}
          />
        )}
        {mobileImages?.[1] && (
          <ImageOrVideo
            type="image"
            {...mobileImages[1]}
            className={getClassName(true, !singleImage)}
            loading={lazyLoadImage ? 'lazy' : 'eager'}
          />
        )}
      </Link>
    </Container>
  );
};

export default Renderer({ name: 'modulePromoBumper' })(PromoBumper);
