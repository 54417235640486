import { FC } from 'react';

import { Text } from '@components/ui';

interface NextStepsTimelineProps {
  timeline: any[];
}

const NextStepsTimeline: FC<NextStepsTimelineProps> = ({ timeline }) => {
  return (
    <div className="flex flex-col  max-w-[300px] relative">
      <hr
        className="w-[3px] h-full absolute "
        style={{
          backgroundImage:
            'linear-gradient(0deg, transparent, transparent 50%, #8652FF 50%, #8652FF 100%), linear-gradient(0deg, #8652FF 0%, #fff 49%, #8652FF)',
          backgroundSize: '3px 20px, 3px 100%',
          border: 'none',
        }}
      />
      {timeline?.map((item: any, index: number) => {
        const { title, description } = item.content;
        return (
          <div key={item.id} className="flex  items-center  relative">
            <div className="absolute left-[-12px]">
              <span className="bg-white  rounded-[50px] flex justify-center w-[24px] h-[24px]">{index + 1}</span>
            </div>
            <div className="p-6">
              <Text color="#fff" variant="base" className="font-bold">
                {title}
              </Text>
              <Text color="#fff">{description}</Text>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NextStepsTimeline;
