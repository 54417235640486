export enum FontTypes {
  Normal = 'Normal',
  Bulky = 'Bulky',
}

export enum FontSizes {
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}
